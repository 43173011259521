import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useCart, useGuest, useLanguage, useTheme } from "../../utils/Hooks";
import Text, { Price, Title } from "../common/Text";
import { toast } from "react-toastify";
import NoContent from "../common/NoContent";
import { Badge } from "react-bootstrap";

function Products({
  products = [],
  categories,
  subCategories,
  setOpenGuestModal,
  SearchQuery,
}) {
  const navigate = useNavigate();
  const { tenant, branch, table } = useParams();
  const { userLanguage } = useLanguage();
  const theme = localStorage.getItem("theme-mode");
  const { style, AddOns } = useTheme();
  const [grid, setGrid] = useState(true);
  const { useAddProduct, Socket } = useCart();

  const { guest } = useGuest();

  const [socket, setSocket] = useState(null);
  useEffect(() => {
    setSocket(Socket);
  }, [Socket]);

  const toProduct = (id) => {
    navigate(
      table
        ? `/${tenant}/${branch}/${table}/product/${id}`
        : `/${tenant}/${branch}/product/${id}`
    );
  };

  const AddCart = (product) => {
    if (style?.is_branch_closed) {
      toast.warn(<Text title={"BranchClosed"} />);
    } else {
      useAddProduct({ ...product, Modifiers: [], Variants: [], Counter: 1 });
    }
  };

  const AddCartSocket = (product) => {
    if (style?.is_branch_closed) {
      toast.warn(<Text title={"BranchClosed"} />);
    } else {
      if (guest) {
        if (socket && product) {
          socket.send(
            JSON.stringify({
              action: "add_or_update_item",
              language: userLanguage,
              payload: {
                product_id: product?.id,
                qty: 1,
                modifiers: [],
              },
            })
          );
          toast(<Text title={"productAdded"} />, {
            theme: theme === "dark-mode" ? "dark" : "light",
          });
        }
      } else setOpenGuestModal(true);
    }
  };

  return (
    <>
      <div className="row mb-3">
        <div className="col-8 align-self-center">
          <div className="d-flex flex-row">
            {SearchQuery ? (
              <h6 className="title">
                <Text title="Search Results" />{" "}
              </h6>
            ) : (
              <>
                <h6 className="title">
                  <Title
                    title={categories?.title}
                    en={categories?.title_en}
                    ar={categories?.title_ar}
                  />{" "}
                </h6>
                {categories?.show_subcategories && (
                  <h6 className="title">
                    {" / "}
                    <Title
                      title={subCategories?.title}
                      en={subCategories?.title_en}
                      ar={subCategories?.title_ar}
                    />
                  </h6>
                )}
              </>
            )}
            <p className="text-secondary small mx-2 align-self-center">
              {products.length}
            </p>
          </div>
        </div>
        <div className="col-4 d-flex justify-content-end no-padding-sm">
          <nav>
            <div className="nav nav-tabs border-0" id="nav-tab" role="tablist">
              <button
                className={
                  "btn btn-44 btn-outline-dark text-normalcase mx-2 " +
                  (grid && "active")
                }
                type="button"
                role="tab"
                onClick={() => setGrid(true)}
              >
                <i className="bi bi-grid"></i>
              </button>
              <button
                className={
                  "btn btn-44 btn-outline-dark text-normalcase ms-2 " +
                  (!grid && "active")
                }
                type="button"
                role="tab"
                onClick={() => setGrid(false)}
              >
                <i className="bi bi-list"></i>
              </button>
            </div>
          </nav>
        </div>
      </div>
      {products?.length ? (
        grid ? (
          <div className="row pb-5">
            {products?.map((product, index) => (
              <div
                className={`col-6 col-md-4 col-lg-${
                  AddOns?.["Online Ordering"] ? 6 : 3
                }`}
                key={index}
              >
                <div className="card mb-3 max-height" role={"button"}>
                  <div
                    className="card-body p-1 position-relative"
                    onClick={() => toProduct(product?.id)}
                  >
                    {product?.is_outofstock && (
                      <Badge className="outofstock" bg={"theme"}>
                        <Text title={"out_of_stock"} />
                      </Badge>
                    )}
                    <img
                      alt=""
                      src={
                        product?.is_image_url
                          ? product?.image_url
                          : product?.image
                      }
                      className="avatar h-140 w-100 rounded-15 border coverimg"
                    />
                  </div>
                  <div className="card-body pt-2">
                    <div className="row">
                      <div
                        className="col"
                        onClick={() => toProduct(product?.id)}
                      >
                        <p className="small">
                          <small className="size-14">
                            <Title
                              title={product?.title}
                              en={product?.title_en}
                              ar={product?.title_ar}
                            />
                          </small>
                          <br />
                          <small className="text-muted size-12">
                            <Price price={product?.formated_price} />
                          </small>
                        </p>
                      </div>
                      <div className="col-auto px-0">
                        {!product?.is_outofstock && (
                          <button
                            className="btn btn-sm btn-link text-color-theme"
                            onClick={() =>
                              product?.modifier_classes_count
                                ? toProduct(product?.id)
                                : table
                                ? AddCartSocket(product)
                                : AddCart(product)
                            }
                          >
                            <i className="bi bi-bag-plus"></i>
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="row pb-5">
            <div className="col-12">
              <div className="card">
                <div className="card-body p-0">
                  <ul className="list-group list-group-flush bg-none">
                    {products?.map((product, index) => (
                      <li className="list-group-item" key={index}>
                        <div className="row" role={"button"}>
                          <div
                            className="col-auto"
                            onClick={() => toProduct(product?.id)}
                          >
                            <div className="card border shadow-none">
                              <div className="card-body p-1">
                                <img
                                  className="avatar avatar-50 rounded-15 coverimg"
                                  alt=""
                                  src={
                                    product?.is_image_url
                                      ? product?.image_url
                                      : product?.image
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            className="col align-self-center ps-0"
                            onClick={() => toProduct(product?.id)}
                          >
                            <p>
                              <Title
                                title={product?.title}
                                en={product?.title_en}
                                ar={product?.title_ar}
                              />
                            </p>
                          </div>

                          <div
                            className="col align-self-center text-end d-flex justify-content-end"
                            onClick={() => toProduct(product?.id)}
                          >
                            {product?.is_outofstock && (
                              <Badge
                                bg={"theme"}
                                style={{ marginInline: "10px" }}
                              >
                                <Text title={"out_of_stock"} />
                              </Badge>
                            )}
                            <p>
                              <Price price={product?.formated_price} />
                            </p>
                          </div>
                          <div
                            className="col-auto px-0"
                            style={{ alignSelf: "center" }}
                          >
                            {!product?.is_outofstock && (
                              <button
                                className="btn btn-sm btn-link text-color-theme"
                                onClick={() =>
                                  product?.modifier_classes_count
                                    ? toProduct(product?.id)
                                    : table
                                    ? AddCartSocket(product)
                                    : AddCart(product)
                                }
                              >
                                <i className="bi bi-bag-plus"></i>
                              </button>
                            )}
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        )
      ) : (
        <NoContent />
      )}
    </>
  );
}

export default Products;
