import React, { useState, useEffect, Fragment } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Container from "../components/common/Container";
import Text, { Price, Title } from "../components/common/Text";
import { useCart, useGuest, useLanguage, useTheme } from "../utils/Hooks";
import EmptyCartSection from "../components/common/EmptyCart";
import CheckoutModal from "../components/common/CheckoutModal";
import { toast } from "react-toastify";
import { useCheckout } from "../utils/API";
import { Form } from "react-bootstrap";
import Spinner from "../components/common/Spinner";
import { getQueryParams } from "../utils/helpers";

function Cart() {
    const { tenant, branch, table } = useParams();
    const navigate = useNavigate();
    const { guest } = useGuest();
    const { userLanguage } = useLanguage();
    const { cart, useClearCart, useRemoveProduct, Socket, Updates } = useCart();

    const { style, AddOns } = useTheme();

    const [Cart, setCart] = useState(cart);
    const [socket, setSocket] = useState(null);
    const [cartUpdate, setCartUpdate] = useState(false);
    const [socketLoading, setSocketLoading] = useState(true);

    const [openModal, setOpenModal] = useState(false);

    useEffect(() => {
        setSocket(Socket);
    }, [Socket]);

    useEffect(() => {
        if (cart?.id) setSocketLoading(false);
        setCartUpdate(cart);
    }, [cart]);

    const EmptyCart = () => {
        const cart = useClearCart();
        setCart(cart);
    };

    const ConfirmCartSocket = () => {
        if (style?.is_branch_closed) {
            toast.warn(<Text title={"BranchClosed"} />);
        } else {
            if (socket) {
                socket.send(
                    JSON.stringify({
                        action: "confirm_guest_items",
                        language: userLanguage,
                        payload: {},
                    })
                );
            }
        }
    };

    const EmptyGuestCartSocket = () => {
        if (socket) {
            socket.send(
                JSON.stringify({
                    action: "empty_guest_cart",
                    language: userLanguage,
                    payload: {},
                })
            );
        }
    };

    const setCounter = (counter, index) => {
        const cart = [...Cart];
        cart[index].Counter = counter;
        setCart(cart);
    };

    const setCounterSocket = (product, operation) => {
        if (socket && product) {
            socket.send(
                JSON.stringify({
                    action: "add_or_update_item",
                    language: userLanguage,
                    payload: {
                        product_id: product?.product_id,
                        qty: (operation === "add" ? 1 : -1) + product?.qty,
                        modifiers: product?.modifiers?.map((mod) => ({ id: mod?.modifier_id, qty: (operation === "add" ? 1 : -1) + product?.qty })),
                    },
                })
            );
        }
    };

    const RemoveCart = (product) => {
        const cart = useRemoveProduct(product);
        setCart(cart);
    };

    const RemoveCartSocket = (item_id) => {
        if (socket && item_id) {
            socket.send(
                JSON.stringify({
                    action: "delete_item",
                    language: userLanguage,
                    payload: {
                        item_id,
                    },
                })
            );
        }
    };

    const toProduct = async (id) => {
        const queryString = await getQueryParams();
        const basePath = table ? `/${tenant}/${branch}/${table}/product/${id}` : `/${tenant}/${branch}/product/${id}`;
        navigate(basePath + (queryString ? `?${queryString}` : ""));
    };

    const TotalPrice = table
        ? null
        : Cart?.map(({ price, Counter, Modifiers, Variants }) => {
              const modifiersPrice = Modifiers?.map((modifier) => parseFloat(modifier.price))
                  .reduce((partialSum, a) => partialSum + a, 0)
                  .toFixed(3);
              const variantsPrice = Variants?.map((variant) => parseFloat(variant.price))
                  .reduce((partialSum, a) => partialSum + a, 0)
                  .toFixed(3);
              return (parseFloat(price) + parseFloat(modifiersPrice) + parseFloat(variantsPrice)) * (Counter || 1);
          })
              .reduce((partialSum, a) => partialSum + a, 0)
              .toFixed(3);

    const calculateTotalItemsValue = () => {
        let totalItemsValueSum = 0;

        cartUpdate.guests.forEach((guest) => {
            guest.items.forEach((item) => {
                totalItemsValueSum += item.total_items_value;
            });
        });

        return totalItemsValueSum.toFixed(3);
    };

    const handlePlaceOrder = () => {
        if (style?.is_branch_closed) {
            toast.warn(<Text title={"BranchClosed"} />);
        } else {
            navigate(`/${tenant}/${branch}/place-order`);
        }
    };

    const handlePay = () => {
        if (style?.is_branch_closed) {
            toast.warn(<Text title={"BranchClosed"} />);
        } else {
            setOpenModal(true);
        }
    };

    const { mutate: checkout, data: checkoutResults } = useCheckout();
    useEffect(() => {
        if (Cart?.length) {
            checkout({
                tenant_slug: tenant,
                branch_slug: branch,
                items: Cart?.map((item) => ({
                    product_id: item?.id,
                    qty: item?.Counter,
                    modifiers: item?.Modifiers?.map((modifier) => ({
                        modifier_id: modifier?.id,
                        qty: item?.Counter,
                    })),
                })),
            });
        }
    }, [Cart]);
    const [CheckoutResults, setCheckoutResults] = useState(false);
    useEffect(() => {
        if (checkoutResults?.data?.responseData) setCheckoutResults(checkoutResults?.data?.responseData);
    }, [checkoutResults?.data?.responseData]);

    return (
        <Container title="Cart" viewBottomTab={true} viewAlert={true}>
            {table ? (
                socketLoading && guest ? (
                    <Spinner />
                ) : cartUpdate?.guests?.length ? (
                    <>
                        <div className="row dashedBorder rounded mt-4 py-3 mx-1">
                            <div className="col d-flex flex-column justify-content-between align-items-center">
                                <div className="d-flex mb-3 justify-content-evenly align-items-center w-100">
                                    <h5>
                                        <Text title={`${cartUpdate?.table?.table_no}`} />
                                    </h5>
                                    <h5>
                                        <Text title={`${cartUpdate?.order_no}`} />
                                    </h5>
                                </div>
                                <div className="d-flex">
                                    {cartUpdate?.is_paid || Updates?.is_paid ? (
                                        <h3 className="text-success fs-22">
                                            <Text title={`Paid`} />
                                        </h3>
                                    ) : (
                                        <>
                                            <button className="btn btn-outline-success shadow-sm btn-sm btn-rounded fixed-btn" onClick={ConfirmCartSocket} disabled={!cartUpdate?.guests?.some((Guest) => Guest.items.some((item) => item.is_editable && item.is_owner))}>
                                                <Text title={"Confirm"} />
                                            </button>
                                            <span className="mx-1"></span>
                                            <button className="btn btn-outline-info shadow-sm btn-sm btn-rounded fixed-btn" onClick={handlePay} disabled={!cartUpdate?.guests?.every((Guest) => Guest.items.every((item) => item.item_status_id === 4))}>
                                                <Text title={"CheckOut"} />
                                            </button>
                                            <span className="mx-1"></span>
                                            <button className="btn btn-outline-danger shadow-sm btn-sm btn-rounded fixed-btn" onClick={EmptyGuestCartSocket} disabled={!cartUpdate?.guests?.some((Guest) => Guest.items.some((item) => item.is_editable && item.is_owner))}>
                                                <Text title={"EmptyGuest"} />
                                            </button>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                        {cartUpdate?.guests?.map((guest, index) => (
                            <Fragment key={index}>
                                <div className="row pt-4">
                                    <div className="col align-self-center blockquote fw-bold mb-0" style={{ fontSize: 18 }}>
                                        <Text title={guest?.name} />
                                        <span className="mx-2 text-success">
                                            {"("}
                                            {guest?.items?.length} <Text title={`items`} />
                                            {")"}
                                        </span>
                                    </div>
                                    <div className="col align-self-center text-end blockquote mb-0" style={{ fontSize: 18 }}>
                                        <span className="mx-2">
                                            {guest?.items?.reduce((partialSum, a) => partialSum + a?.total_items_value, 0).toFixed(3)} {guest?.items[0]?.currency}
                                        </span>
                                    </div>
                                </div>
                                <div className="row my-3 position-relative">
                                    {guest?.items?.map((product, index) => (
                                        <div key={index} className="col-12 col-md-6 col-lg-12" role={"button"}>
                                            <div
                                                className="card mb-3"
                                                style={{
                                                    filter: product?.is_owner ? "" : "grayscale(60%)",
                                                    opacity: product?.is_owner ? "" : "0.75",
                                                }}
                                            >
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-auto pe-0 position-relative d-flex flex-column justify-content-center align-items-center">
                                                            <div className="position-absolute z-index-1 p-1 close-btn">
                                                                {product?.is_editable && (
                                                                    <button className="btn btn-sm btn-danger btn-26 rounded-circle shadow-sm shadow-light text-light" onClick={() => RemoveCartSocket(product?.item_id)}>
                                                                        <i className="bi bi-x-lg size-10 vm"></i>
                                                                    </button>
                                                                )}
                                                            </div>
                                                            <img alt="" src={product?.product_image} className="avatar avatar-60 rounded-15 coverimg text-center mt-2" onClick={() => toProduct(product?.product_id)} />
                                                        </div>
                                                        <div className="col align-self-center mb-3" onClick={() => toProduct(product?.product_id)}>
                                                            <p className="mb-1">
                                                                <Title title={product?.full_line} en={product?.full_line} ar={product?.full_line} />
                                                            </p>
                                                            <p className="mb-1 text-muted">
                                                                <Price price={product?.total_item} />
                                                            </p>
                                                            {product?.Variants?.map((variant, varIndex) => (
                                                                <div key={varIndex} className="d-flex flex-row justify-content-between">
                                                                    <p className="size-10 mb-1">
                                                                        <Title title={variant?.title} en={variant?.title_en} ar={variant?.title_ar} />
                                                                    </p>
                                                                    <p className="size-10 mb-1">
                                                                        <Price price={variant?.formated_price} />
                                                                    </p>
                                                                </div>
                                                            ))}
                                                            {product?.modifiers?.map((modifier, modIndex) => (
                                                                <div key={modIndex} className="d-flex flex-row justify-content-between">
                                                                    <p className="size-10 mb-1">
                                                                        <Title title={modifier?.full_line} en={modifier?.title_en} ar={modifier?.full_line} />
                                                                    </p>
                                                                    <p className="size-10 mb-1">
                                                                        <Price price={modifier?.total_modifier_value} />
                                                                    </p>
                                                                </div>
                                                            ))}
                                                        </div>
                                                        <div className="col-auto align-self-center" style={{ minHeight: 90 }}>
                                                            {product?.is_editable && (
                                                                <div className="counter-number vertical">
                                                                    <button className="btn btn-sm avatar avatar-30 p-0 rounded-circle" onClick={() => setCounterSocket(product, "remove")}>
                                                                        <i className="bi bi-dash size-22"></i>
                                                                    </button>
                                                                    <span>{product?.qty || 1}</span>
                                                                    <button className="btn btn-sm avatar avatar-30 p-0 rounded-circle" onClick={() => setCounterSocket(product, "add")}>
                                                                        <i className="bi bi-plus size-22"></i>
                                                                    </button>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="text-end">
                                                            <strong
                                                                style={{
                                                                    fontSize: 12,
                                                                    backgroundColor: product?.item_status_color,
                                                                }}
                                                                className={"mt-2 px-2 rounded text-light"}
                                                            >
                                                                {product?.item_status_text}
                                                            </strong>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </Fragment>
                        ))}
                        <div className="row fw-bold mb-5">
                            <div className="mb-3 col-12">
                                <div className="dashed-line"></div>
                            </div>

                            <div className="col-6 blockquote">
                                <h5 className="mb-0 text-muted">
                                    <Text title={"Subtotal"} />
                                </h5>
                            </div>
                            <div className="d-flex justify-content-end col-6 blockquote">
                                <h5 className="mb-0 text-muted">{Updates?.subtotal_formatted || cartUpdate?.subtotal_formatted}</h5>
                            </div>
                            {(Updates?.applied_taxes || cartUpdate?.applied_taxes)?.map((item, i) => (
                                <Fragment key={i}>
                                    <div className="col-6 blockquote">
                                        <h5 className="mb-0 text-muted">{item?.label}</h5>
                                    </div>
                                    <div className="d-flex justify-content-end col-6 blockquote">
                                        <h5 className="mb-0 text-muted"> {item?.value}</h5>
                                    </div>
                                </Fragment>
                            ))}
                            <div className="col-6 blockquote">
                                <h4 className="mb-0">
                                    <Text title={"Total"} />
                                </h4>
                            </div>
                            <div className="d-flex justify-content-end col-6 blockquote">
                                <h4 className="mb-0">{Updates?.total_formatted || cartUpdate?.total_formatted}</h4>
                            </div>
                        </div>
                    </>
                ) : (
                    <EmptyCartSection branch={branch} table={table} tenant={tenant} />
                )
            ) : Cart?.length > 0 ? (
                <>
                    <div className="row my-3 mt-5">
                        {Cart?.map((product, index) => (
                            <div key={index} className={`col-12 col-md-6 col-lg-${AddOns?.["Online Ordering"] ? 12 : 6}`} role={"button"}>
                                <div className="card mb-3">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-auto pe-0 position-relative">
                                                <div className="position-absolute  z-index-1 p-1">
                                                    <button className="btn btn-sm btn-light btn-26 roudned-circle shadow-sm shadow-danger text-danger" onClick={() => RemoveCart(product)}>
                                                        <i className="bi bi-x-lg size-10 vm"></i>
                                                    </button>
                                                </div>
                                                <img alt="" src={product?.is_image_url ? product?.image_url : product?.image} className="avatar avatar-80 rounded-15 coverimg text-center" onClick={() => toProduct(product?.product_id)} />
                                            </div>
                                            <div className="col align-self-center" onClick={() => toProduct(product?.id)}>
                                                <p className="mb-0">
                                                    <small className="text-muted size-12">
                                                        <Title title={product?.title} en={product?.title_en} ar={product?.title_ar} />
                                                    </small>
                                                </p>
                                                <h5 className="mb-1">
                                                    <Price price={product?.price} />
                                                </h5>
                                                {product?.Variants?.map((variant, varIndex) => (
                                                    <div key={varIndex} className="d-flex flex-row justify-content-between">
                                                        <p className="size-10 mb-1">
                                                            <Title title={variant?.title} en={variant?.title_en} ar={variant?.title_ar} />
                                                        </p>
                                                        <p className="size-10 mb-1">
                                                            <Price price={variant?.price} />
                                                        </p>
                                                    </div>
                                                ))}
                                                {product?.Modifiers?.map((modifier, modIndex) => (
                                                    <div key={modIndex} className="d-flex flex-row justify-content-between">
                                                        <p className="size-10 mb-1">
                                                            <Title title={modifier?.title} en={modifier?.title_en} ar={modifier?.title_ar} />
                                                        </p>
                                                        <p className="size-10 mb-1">
                                                            <Price price={modifier?.price} />
                                                        </p>
                                                    </div>
                                                ))}
                                            </div>
                                            <div className="col-auto align-self-center">
                                                <div className="counter-number vertical">
                                                    <button className="btn btn-sm avatar avatar-30 p-0 rounded-circle" onClick={() => setCounter(product?.Counter > 0 ? product?.Counter - 1 : product?.Counter, index)}>
                                                        <i className="bi bi-dash size-22"></i>
                                                    </button>
                                                    <span>{product?.Counter || 1}</span>
                                                    <button className="btn btn-sm avatar avatar-30 p-0 rounded-circle" onClick={() => setCounter((product?.Counter || 1) + 1, index)}>
                                                        <i className="bi bi-plus size-22"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="card my-3">
                        <div className="card-body">
                            <Form.Label className="fw-bold" htmlFor="note">
                                <Text title="Summary" />
                            </Form.Label>
                            {!!CheckoutResults?.length &&
                                CheckoutResults?.map(
                                    (item) =>
                                        item?.value && (
                                            <div className="row my-2" key={item?.label}>
                                                <div className="col">
                                                    <p>
                                                        <Text title={item?.label} />
                                                    </p>
                                                </div>
                                                <div className="col-auto fw-bold">{item?.value}</div>
                                            </div>
                                        )
                                )}
                        </div>
                    </div>

                    <div className="row my-4">
                        {AddOns?.["Online Ordering"] && (
                            <div className="col-12 mb-3">
                                <button className="btn btn-primary shadow-sm btn-lg w-100 btn-rounded" onClick={handlePlaceOrder}>
                                    <Text title={"Place Order"} />
                                </button>
                            </div>
                        )}
                        <div className="col-12">
                            <button className="btn btn-default shadow-sm btn-lg w-100 btn-rounded" onClick={EmptyCart}>
                                <Text title={"Empty"} />
                            </button>
                        </div>
                    </div>
                </>
            ) : (
                <EmptyCartSection branch={branch} table={table} tenant={tenant} />
            )}

            <CheckoutModal openModal={openModal} setOpenModal={setOpenModal} />
        </Container>
    );
}

export default Cart;
