import React from "react";
import Text from "./Text";
import { Link, useNavigate, useParams } from "react-router-dom";
import SocialLinks from "./SocialLinks";
import { getQueryParams } from "../../utils/helpers";

function Copyright({ isContainer }) {
    const { tenant, branch, table } = useParams();
    const navigate = useNavigate();

    const handleNavigation = async () => {
        const queryString = await getQueryParams();
        const basePath = table ? `/${tenant}/${branch}/${table}/aboutUs` : `/${tenant}/${branch}/aboutUs`;
        navigate(basePath + (queryString ? `?${queryString}` : ""));
    };

    return (
        <>
            <div className="d-block d-lg-none">{isContainer && <SocialLinks />}</div>
            <p className="text-muted text-center py-2 ">
                <Text title={"Copyright"} /> {" © "} {new Date().getFullYear()} {" | "}
                <Text title={"PoweredBy"} />
                {tenant && branch ? (
                    <>
                        {" | "}
                        <Link className="text-muted" onClick={handleNavigation} to={"#"}>
                            <Text title={"aboutUs"} />
                        </Link>
                    </>
                ) : null}
            </p>
        </>
    );
}

export default Copyright;
