import React, { useEffect, useRef, useState, useLayoutEffect } from "react";
// import Loader from "./Loader";
import Sidebar from "./Sidebar";
import HeaderMenu from "./HeaderMenu";
import BottomTab from "./BottomTab";
import SearchBar from "../Home/SearchBar";
// import FilterModal from "../Home/FilterModal";
import { useTheme } from "../../utils/Hooks";
import Copyright from "./Copyright";
import GuestInfoModal from "../Home/GuestInfoModal";
import { Alert } from "react-bootstrap";
import Text from "./Text";
import splash from "../../assets/img/default-desktop-bg.webp";
import HomeSlider from "./HomeSlider";
import SocialLinks from "./SocialLinks";
import Spinner from "./Spinner";
import Logo from "./Logo";

function Container({ children, viewSearch, viewBottomTab, isLoading, SearchQuery, setSearchQuery, openGuestModal, title = "", viewAlert, landing }) {
    const { style, AddOns } = useTheme();
    const [bgImage, setBgImage] = useState(style["bgImage-mode"]);
    const [Loading, setLoading] = useState(true);
    // const [openFilter, setOpenFilter] = useState(false);
    const [openMenu, setOpenMenu] = useState(false);
    const headerRef = useRef();
    const footerRef = useRef();

    const [scrolled, setScrolled] = useState(false);

    useLayoutEffect(() => {
        document.title = `QR Menu | ${title}`;
    }, [title]);

    useEffect(() => {
        window.addEventListener("scroll", (e) => {
            if (window.scrollY) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        });
        setLoading(isLoading);
    }, [isLoading]);

    useEffect(() => {
        setBgImage(style["bgImage-mode"]);
    }, [style]);

    useEffect(() => {
        if (openMenu) {
            document.body.classList.add("menu-open");
        } else {
            document.body.classList.remove("menu-open");
        }
    }, [openMenu]);

    const scrollableColumnRef = useRef(null);
    const fixedColumnRef = useRef(null);

    useEffect(() => {
        const handleWheel = (event) => {
            if (scrollableColumnRef.current && fixedColumnRef.current) {
                const deltaY = event.deltaY;
                const scrollPosition = scrollableColumnRef.current.scrollTop + deltaY * 0.1;
                scrollableColumnRef.current.scrollTop = scrollPosition;
            }
        };

        fixedColumnRef.current?.addEventListener("wheel", handleWheel);

        return () => {
            fixedColumnRef.current?.removeEventListener("wheel", handleWheel);
        };
    }, []);

    const isHome = title === "Home";

    return (
        <div className="row d-flex max-container p-0 m-0">
            <div className={`col-12 col-lg-${AddOns?.["Online Ordering"] ? 5 : 12} p-0 m-0 scrollable-column`} ref={scrollableColumnRef}>
                <div className={"body-scroll bg-theme transform-page-scale " + (openMenu && "menu-open")} data-page="shop">
                    <Sidebar setOpenMenu={setOpenMenu} />
                    <main
                        className={`theme-light-radial-gradient ${bgImage} ${window?.innerWidth > 993 ? "pt-lg" : AddOns?.["Online Ordering"] ? (isHome ? "pt-0" : "pt-lg") : "pt-lg"}`}
                        style={{
                            minHeight: "100%",
                            paddingBottom: footerRef?.current?.clientHeight + 10 || "",
                        }}
                        onClick={() => {
                            openMenu && setOpenMenu(false);
                        }}
                    >
                        <HeaderMenu setOpenMenu={setOpenMenu} openMenu={openMenu} headerRef={headerRef} landing={landing} />

                        <div
                            className="main-container container"
                            style={{
                                minHeight: window?.outerHeight - headerRef?.current?.clientHeight - 10 || "",
                            }}
                        >
                            {title === "Home" && (
                                <div className="ads-container col-12 p-0 m-n1 d-none d-lg-block">
                                    <HomeSlider removeBg={true} />
                                </div>
                            )}
                            {viewAlert && style?.is_branch_closed && (
                                <Alert variant="warning" className="mt-4 mb-0 text-center">
                                    <strong className="text-secondary fs-5 d-flex justify-content-center align-items-center">
                                        <i className="bi bi-exclamation-circle mx-2 fs-4" />
                                        <Text title={"BranchClosed"} />
                                    </strong>
                                </Alert>
                            )}
                            {viewSearch && (
                                <SearchBar
                                    // setOpenFilter={setOpenFilter}
                                    SearchQuery={SearchQuery}
                                    setSearchQuery={setSearchQuery}
                                />
                            )}
                            {Loading ? <Spinner /> : children}
                        </div>
                        <Copyright isContainer={true} />
                    </main>
                    {viewBottomTab && <BottomTab footerRef={footerRef} />}
                    {/* <FilterModal openFilter={openFilter} setOpenFilter={setOpenFilter} /> */}
                    <GuestInfoModal isLoading={isLoading} openGuestModal={openGuestModal} />
                </div>
            </div>
            {AddOns?.["Online Ordering"] && (
                <>
                    <div className="col-12 col-lg-7 p-0 m-n1 zIndex-9 position-relative d-none d-lg-block" ref={fixedColumnRef}>
                        <SocialLinks />
                        <div className={style?.is_overlay_active ? "image-overlay" : "image-default"}>
                            <img src={style?.desktop_image || splash} alt="bg-image" className="splash-img" />
                            {!!(AddOns?.["Online Ordering"] && style?.is_desktop_logo_centered) && <Logo cover={true} />}
                        </div>
                    </div>
                    {!openMenu && title === "Home" && (
                        <div className="col-12 col-lg-7 p-0 m-n1 d-block d-lg-none" ref={fixedColumnRef}>
                            <HomeSlider />
                        </div>
                    )}
                </>
            )}
        </div>
    );
}

export default Container;
